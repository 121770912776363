<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <div class="item-name-div">名称：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:60" v-slot="{ errors }">
              <label><input class="video-name-input" name="title" type="text" v-model="pinInfo.name" maxlength="60" placeholder="请输入名称"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">价格：</div>
          <div class="item-content-div">
            <validation-provider rules="required|amount" v-slot="{ errors }">
              <label><input class="price-input" name="price" type="text" v-model="pinInfo.price" placeholder="请输入价格"> 元</label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">视频文件：</div>
          <div>
            <input class="cursor-el" type="file" @change="onAddFile($event)"/>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">时长：</div>
          <div class="item-content-div">
            <validation-provider rules="required|numeric" v-slot="{ errors }">
              <label><input name="videoTime" type="text" v-model="pinInfo.time" placeholder="请输入播放时长">分钟</label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="operate-div">
          <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
          <div class="text-c">
            <input type="button" value="取消" @click="onCancel">
            <input class="save-input" type="button" value="保存" @click="onSave">
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { getCancelTokenSource, postLessonMsgAdd } from "@/common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    lessonId: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      pinInfo: {
        name: "",
        time: 0,
        price: 0
      },
      mediaFile: null,
      tipMsg: ""
    }
  },
  methods: {
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onAddFile(event) {
      if(event.target.files.length < 1){
        return;
      }
      this.mediaFile = event.target.files[0];
    },
    onSave() {
      if(this.mediaFile == null){
        this.setTipsMsg("请上传视频");
        return;
      }

      let formData = new FormData();
      formData.append("name", this.pinInfo.name);
      formData.append("time", this.pinInfo.time);
      formData.append("price", `${this.pinInfo.price * 100}`);
      formData.append("lessonId", this.lessonId);
      formData.append("file", this.mediaFile);

      this.$refs.form.validate().then(res => {
        if(res){
          this.uploadMsg = {
            source: getCancelTokenSource(),
            name: this.mediaFile.name,
            total: 0,
            loaded: 0,
            state: 3,
          }
          this.$store.state.uploadQueue.push(this.uploadMsg);
          postLessonMsgAdd(formData, this.uploadMsg.source.token, this.onUploadProgress)
              .then(() => {
                this.uploadMsg.state = 1;
              })
              .catch(error => {
                if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                  this.uploadMsg.state = error.data.status === -1 ? -1 : 0;
                } else {
                  this.uploadMsg.state = 0;
                }
              });

          this.$layer.close(this.layerid);
        }
      });
    },
    onUploadProgress(progressEvent) {
      this.uploadMsg.total = progressEvent.total;
      this.uploadMsg.loaded = progressEvent.loaded;
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .item-name-div {
        width: 70px;
        text-align: right;
      }
      .item-content-div {
        flex: 1;
        display: inline-flex;
        input {
          width: 108px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
        .video-name-input {
          width: 218px;
        }
        .price-input {
          width: 198px;
        }
        .err-tips {
          margin-left: 5px;
        }
      }
    }
    .operate-div {
      margin-top: 10px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
      }
    }
  }
}
</style>