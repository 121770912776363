<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <div class="item-name-div">名称：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:60" v-slot="{ errors }">
              <label><input class="video-name-input" name="title" type="text" v-model="pinInfo.name" maxlength="60" placeholder="请输入名称"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">价格：</div>
          <div class="item-content-div">
            <validation-provider rules="required|amount" v-slot="{ errors }">
              <label><input class="price-input" name="price" type="text" v-model="pinInfo.price" placeholder="请输入价格"> 元</label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="operate-div">
          <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
          <div class="text-c">
            <input type="button" value="取消" @click="onCancel">
            <input class="save-input" type="button" value="保存" @click="onSave">
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { postLessonMsgEdit } from "@/common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    lessonMsg: {
      type: Object,
      default: null
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      pinInfo: {
        name: "",
        price: 0
      },
      tipMsg: ""
    }
  },
  created() {
    if(this.lessonMsg == null){
      this.$layer.close(this.layerid);
      this.$layer.msg("请选择课时");
      return;
    }

    this.pinInfo.name = this.lessonMsg.name;
    this.pinInfo.price = this.lessonMsg.price / 100;
  },
  methods: {
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onSave() {
      let formData = new FormData();
      formData.append("id", this.lessonMsg.id);
      formData.append("name", this.pinInfo.name);
      formData.append("price", `${this.pinInfo.price * 100}`);

      this.$refs.form.validate().then(res => {
        if(res){
          postLessonMsgEdit(formData)
              .then(() => {
                this.refreshData();
                this.$layer.close(this.layerid);
                this.$layer.msg("操作成功");
              })
              .catch(error => {
                if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                  this.$layer.msg(error.data.msg);
                } else {
                  this.$layer.msg("操作失败");
                }
              });
        }
      });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .item-name-div {
        width: 70px;
        text-align: right;
      }
      .item-content-div {
        flex: 1;
        display: inline-flex;
        input {
          width: 108px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
        .video-name-input {
          width: 218px;
        }
        .price-input {
          width: 198px;
        }
        .err-tips {
          margin-left: 5px;
        }
      }
    }
    .operate-div {
      margin-top: 10px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
      }
    }
  }
}
</style>